<template>
    <div class="select-07">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="H" label=""></el-table-column>
            <el-table-column prop="X" label="无奖"></el-table-column>
            <el-table-column prop="X0" label="中0"></el-table-column>
            <el-table-column prop="X4" label="中4"></el-table-column>
            <el-table-column prop="X5" label="中5"></el-table-column>
            <el-table-column prop="X6" label="中6"></el-table-column>
            <el-table-column prop="X7" label="中7"></el-table-column>
        </el-table>
        <div>
            <p>
                综合中奖概率：<span>{{ sumP(tableData) }}</span>
            </p>
            <p>
                综合期望：<span>{{ sumE(tableData) }}</span>
            </p>
            <p>几何分布，期望中奖所需要的次数：{{ 1 / sumP(tableData) }}</p>
            <p>
                几何分布，期望中奖所需要的次数方差：{{
                    (1 - sumP(tableData)) / sumP(tableData) ** 2
                }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "Select07",
    data() {
        return {
            tableData: []
        };
    },
    inject: ["getP", "sumP", "sumE"],
    created() {
        let P0 = this.getP(7, 0);
        let P4 = this.getP(7, 4);
        let P5 = this.getP(7, 5);
        let P6 = this.getP(7, 6);
        let P7 = this.getP(7, 7);
        let X = 1 - P0 - P4 - P5 - P6 - P7;
        let P = {
            H: "P(X)",
            X0: P0,
            X4: P4,
            X5: P5,
            X6: P6,
            X7: P7,
            X: X
        };
        this.tableData.push(P);

        let E = {
            H: "E(X)",
            X0: 0,
            X4: P4 * 2,
            X5: P5 * 26,
            X6: P6 * 286,
            X7: P7 * 9998,
            X: X * -2
        };
        this.tableData.push(E);
    }
};
</script>
<style lang="scss" scoped></style>
